/* eslint-disable */
import axios from "axios";
import Config from "@/services/config.js";

const baseURL = Config.baseURL;

let instanceToken = axios.create({
  baseURL: baseURL
});

let instanceFileUpload = axios.create({
  baseURL: baseURL,
  headers: { "Content-Type": "multipart/form-data" }
});

// Alter defaults after instance has been created
instanceToken.interceptors.request.use(
  function(config) {
    config.headers = {
      "x-conferli-token": JSON.parse(localStorage.getItem("admin")).token
    };
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

// Alter defaults after instance has been created
instanceFileUpload.interceptors.request.use(
  function(config) {
    config.headers = {
      "x-conferli-token": JSON.parse(localStorage.getItem("admin")).token
    };
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

const instance = axios.create({
  baseURL: baseURL
});

export default {
  instanceToken: instanceToken,
  instance: instance,
  instanceFileUpload: instanceFileUpload
  //   translationInstance: translationInstance
};
